import { useState, useEffect, SetStateAction } from "react";
import { Col, Form, FormGroup, Button } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ImPhone } from "react-icons/im";
import { useTranslation } from "react-i18next";

import { ContactPhoneFormModel } from "../../models/ContactPhoneFormModel";

import {
  NameSpace,
  contactForm,
  messagePhoneSuccess,
  buttons,
} from "../../data/AppLanguage";

import ContactPhoneFormSchema from "../../schemas/ContactPhoneFormSchema";

import Util from "../../utils/Util";

import emailjs from '@emailjs/browser'
import ContactService from "../../services/api/Contact";

import PhoneNumberForm from "../PhoneNumberForm/PhoneNumberForm";
import SpinnerAnimation from "../SpinnerAnimation/SpinnerAnimation";

import "./ContactPhoneForm.css";

const ContactPhoneForm = () => {
  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [location, setLocation] = useState('')
  const [submitClicked, setSubmitClicked] = useState(0);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [iconClicked, setIconClicked] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm<ContactPhoneFormModel>({
    resolver: yupResolver(ContactPhoneFormSchema()),
  });

  const { t, i18n } = useTranslation([NameSpace.COMMON]);
  const language = Util.splitLanguage(i18n.language, 1);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (formSubmitted) {
        setTimeout(() => {
          setIconClicked(false);
          setFormSubmitted(false);
          setSubmitClicked(0);
          reset({
            phoneNumber: Util.getCountryCode(language.toLowerCase()),
          });
        }, 2000);
      }
    }

    return () => {
      mounted = false;
    };
  }, [formSubmitted, reset, language]);

  const onSubmit: SubmitHandler<ContactPhoneFormModel> = (data) => {
    setSubmitting(true);
    data.phoneNumber = `+${data.phoneNumber}`;

    ContactService.postContactPhone(language, data)
    .then(() => {
      setSubmitting(false);
      setFormSubmitted(true);
    })
    .catch(() => {
      setSubmitting(false);
    });   
  };

  return (
    <aside>
      <div className={`contact-phone ${iconClicked ? "open" : "animation"}`}>
        {!formSubmitted ? (
          <Form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            className={`contact-phone-form ${iconClicked ? "visible" : ""}`}
          >
            <Form.Row>
              <Form.Group as={Col} 
              controlId="nameInput"             
              className="pb-0">
                <Form.Label column="sm" className="pl-0">
                  {t(`${contactForm}name`)}
                </Form.Label>
                <Form.Control
                  type="text"
                  size="sm"
                  placeholder={t(`${contactForm}name`)}
                  isValid={submitClicked > 0 && !errors.name}
                  isInvalid={!!errors.name}
                  {...register("name")}
                />
              </Form.Group>
              <Form.Group as={Col} 
              controlId="emailInput"             
              className="pb-0">
                <Form.Label column="sm" className="pl-0">
                  {t(`${contactForm}email`)}
                </Form.Label>
                <Form.Control
                  type="text"
                  size="sm"
                  placeholder={t(`${contactForm}email`)}
                  isValid={submitClicked > 0 && !errors.email}
                  isInvalid={!!errors.email}
                  {...register("email")}
                />
                 {errors.email && (
                      <Form.Control.Feedback type="invalid">
                        {errors.email.message}
                      </Form.Control.Feedback>
                    )}
              </Form.Group>              
              <Form.Group as={Col} 
              controlId="locationInput" 
              onChange={(e: { target: { value: SetStateAction<string>; }; }) => setLocation(e.target.value)} value={location}
              className="pb-0">
                <Form.Label column="sm" className="pl-0">
                  {t(`${contactForm}location`)}
                </Form.Label>
                <Form.Control
                  type="text"
                  size="sm"
                  placeholder={t(`${contactForm}location`)}
                  isValid={submitClicked > 0 && !errors.location}
                  isInvalid={!!errors.location}
                  {...register("location")}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                controlId="phoneNumberInput"
                onChange={(e: { target: { value: SetStateAction<string>; }; }) => setPhoneNumber(e.target.value)} value={phoneNumber} 
                className="pb-0"
              >
                <Form.Label column="sm" className="pl-0">
                  {t(`${contactForm}phoneNumber`)}
                </Form.Label>
                <PhoneNumberForm
                  control={control}
                  submitClicked={submitClicked}
                  errors={errors.phoneNumber}
                  inputClassName="phone-number-form-sm"
                  disableDropdown
                />
              </Form.Group>
              <FormGroup
                as={Col}
                className="pl-4 pb-0 align-self-end text-center"
                md="auto"
              >
                {submitting ? (
                  <SpinnerAnimation />
                ) : (
                  <Button
                    type="submit"
                    size="sm"
                    className="contact-phone-form-submit"
                    onClick={() => setSubmitClicked(submitClicked + 1)}
                  >
                    {t(`${buttons}submit`)}
                  </Button>
                )}
              </FormGroup>
            </Form.Row>
          </Form>
        ) : (
          <div
            className={`contact-phone-submit-success ${
              iconClicked ? "visible" : ""
            }`}
          >
            <div className="text-uppercase">
              {t(`${messagePhoneSuccess}v1`)}
            </div>
            <div>{t(`${messagePhoneSuccess}v2`)}</div>
          </div>
        )}
        <div
          className={`d-flex align-items-center justify-content-center ${
            iconClicked ? "pl-5" : ""
          }`}
        >
          <ImPhone
            className="contact-phone-icon"
            onClick={() => {
              if (!formSubmitted && !submitting) setIconClicked(!iconClicked);
            }}
          />
        </div>
      </div>
    </aside>
  );
};

export default ContactPhoneForm;
