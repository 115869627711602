import React, { useEffect, useState, useRef ,useCallback } from "react";
import { Box, Typography } from "@mui/material";
import SubfamilyDataSheetModel from "../../models/SubfamilyDataSheetModel";
import "./CarouselDataSheet.css";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css"






interface Props {
    subfamilyDataSheet?: SubfamilyDataSheetModel;
    handleImageChange: (
        url?: string,
        colorId?: number,
        index?: number,
        data?: any,
        languages?: string,
        table?: any
      ) => void;
      setHasCodeDataSheet: (code: string) => void;
}


const CarouselDataSheet = (props: Props ) => {
    const [subfamily, setSubfamily] = useState<SubfamilyDataSheetModel>();
    const Empty = "https://mm.mafirol.info/empty.png";
    const [currentIndex, setCurrentIndex] = useState(0);
    const [itensPerPage, setItensPerPage] = useState(1);
    const carousel = useRef(null);

    const CarouselProps = {
        showIndex: false,
        showBullets: false,
        infinite: true,
        showThumbnails: true,
        showFullscreenButton: true,
        showGalleryFullscreenButton: true,
        showPlayButton: false,
        showGalleryPlayButton: false,
        showNav: true,
        isRTL: false,
        slideOnThumbnailOver: true,
        thumbnailPosition: 'left',
        useWindowKeyDown: true,
      }

      const images = [];
      useEffect(() =>{    
        setSubfamily(props.subfamilyDataSheet);

        // images.push({
        //     original: subfamily?.subfamilyMediaUrl,
        //     thumbnail: subfamily?.subfamilyMediaUrl,
        //     originalClass: 'featured-slide',
        //     thumbnailClass: 'featured-thumb',
        //     thumbnailHeight: 70,
        //   })
    

        // calculateItemsPerPage();
        // window.addEventListener("resize", calculateItemsPerPage);
        // return () => window.removeEventListener("resize", calculateItemsPerPage);
    },[props.subfamilyDataSheet]);




      images.push({
        original: subfamily?.subfamilyMediaUrl,
        thumbnail: subfamily?.subfamilyMediaUrl,
        originalClass: 'featured-slide',
        thumbnailClass: 'featured-thumb',
        thumbnailHeight: 70,
      })

      subfamily?.dataSheets?.map(datasheet => (
        images.push({
          original: datasheet.urlImgCut,
          thumbnail: datasheet.urlImgCut,
          originalClass: 'featured-slide',
          thumbnailClass: 'featured-thumb',
          thumbnailHeight: 70,
        })
      ))

     

    

    
    const calculateItemsPerPage = () => {
        const width = window.innerWidth;
        if(width >= 1200) {
            setItensPerPage(3);
        }
        else if(width >= 768){
            setItensPerPage(2);
        }
        else {
            setItensPerPage(1);
        }        
    };
    

    const handleLeftClick = (e: React.MouseEvent) => {
        e.preventDefault();
        const width = carousel.current.clientWidth;        
        carousel.current.scrollLeft -= width;
        setCurrentIndex(Math.max(currentIndex - 1, 0));
    }


    const handleRightClick = (e: React.MouseEvent) => {
        e.preventDefault();
        const width = carousel.current.clientWidth;        
        const maxIndex = Math.ceil(subfamily?.dataSheets?.length / itensPerPage) - 1;
        carousel.current.scrollLeft += width;
        setCurrentIndex(Math.min(currentIndex + 1, maxIndex));
        // setCurrentIndex(Math.min(currentIndex + 1, subfamily?.dataSheets?.length - 1));
    }

    const handleBulletClick = (index: number) => {
        const width = carousel.current.clientWidth;
        carousel.current.scrollLeft = index * width;
        setCurrentIndex(index);
    }

    if(!subfamily || !subfamily.dataSheets?.length) return null;

    const totalBullets = Math.ceil(subfamily.dataSheets.length);

   
  
 
    return(       
        <div className="container">              
            <div className="carousel" ref={carousel}>
                {subfamily?.dataSheets.map((item, index) => {                    
                    const indexFormated = index + 1;
                    return(
                        <div className="item" key={item.id} style={{ cursor: "pointer" }}
                        onClick={() => {
                            props.handleImageChange(item?.urlImgCut, undefined, index + 1, item?.dataTable, item?.language, item?.dataTable)
                            props.setHasCodeDataSheet(item?.code ?? "");
                        }}
                        >
                                <div className="image">
                                    <img src={item.urlImgCut || Empty} alt="image"                                     
                                    key={index + 1}                                                                               
                                    />
                                </div>
                                <div className="info">
                                    <span className="name" style={{ fontSize: '.9rem', fontWeight: 500 }}>{item.subtitleText}</span>                                               
                                </div>
                        </div>
                    );
               })}
            </div>
            <div className="bullets">              
               {subfamily.dataSheets.map((_, index) => (
                    <div 
                        key={index}
                        className={`bullet ${index === currentIndex ? "" : ""}`}
                        style={{ cursor: "default" }}                       
                    />
               ))} 
            </div>
            <div className="buttons">
                <div className="button-wrapper">
                    <button onClick={(e) => handleLeftClick(e)}><MdKeyboardArrowLeft/></button>
                </div>
                <div className="button-wrapper">
                    <button onClick={(e) => handleRightClick(e)}><MdKeyboardArrowRight/></button>
                </div>
            </div>
        </div> 
        // </section>
    )
}

export default CarouselDataSheet;