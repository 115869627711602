import { SchemaOf, object, string } from "yup";
import i18n from "i18next";

import { ContactPhoneFormModel } from "../models/ContactPhoneFormModel";

import { NameSpace } from "../data/AppLanguage";

const ContactPhoneFormSchema = (): SchemaOf<ContactPhoneFormModel> =>
  object().shape(
    {
      name: string()
        .trim()
        .required(i18n.t("required", { ns: NameSpace.VALIDATION }))
        .min(
          2,
          i18n.t("nameMinLength", { ns: NameSpace.VALIDATION, number: 2 })
        )
        .max(
          255,
          i18n.t("nameMaxLength", { ns: NameSpace.VALIDATION, number: 255 })
        ),
        email: string()
        .trim()
        .required(i18n.t("required", { ns: NameSpace.VALIDATION }))
        .email(i18n.t("emailInvalid", { ns: NameSpace.VALIDATION })),
      location: string()
        .trim()
        .required(i18n.t("required", { ns: NameSpace.VALIDATION }))
        .when("location", {
          is: (value: any) => value?.length,
          then: string()
            .min(
              2,
              i18n.t("locationMinLength", {
                ns: NameSpace.VALIDATION,
                number: 2,
              })
            )
            .max(
              255,
              i18n.t("locationMaxLength", {
                ns: NameSpace.VALIDATION,
                number: 255,
              })
            ),
          otherwise: string().optional(),
        }),
      phoneNumber: string()
        .trim()
        .validPhoneNumber(
          i18n.t("phoneNumberInvalid", { ns: NameSpace.VALIDATION })
        )
        .required(),
    },
    [["location", "location"]]
  );

export default ContactPhoneFormSchema;
