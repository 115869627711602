import { useTranslation } from "react-i18next";
import { NameSpace } from "../../data/AppLanguage";
import { useContext, useEffect, useState, createContext } from "react";
import { OpportunityContext } from "../../context/ThemeContext";

import Util from "../../utils/Util";
import { Alert } from "react-bootstrap";
import { Box, Container, Grid,  } from "@mui/material";

import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import * as Typogr from "@mui/material";
import CardActions from '@mui/material/CardActions';
import OpportunityGammaModel from "../../models/OpportunityGammaModel";
import { MediaType } from "../../enums/MediaType";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { Link, useHistory } from "react-router-dom";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import OpportunityService from "../../services/Opportunity";
import { MdArrowBack } from "react-icons/md";





const Opportunities = () => {
    const [opporunitiesByGamaList, setOpporunitiesByGamaList] = useState<OpportunityGammaModel[]>([]);
    const [mounted, setMounted] = useState<boolean>(true);
    const history = useHistory();

    const { t, i18n } = useTranslation([
        NameSpace.COMMON,
        NameSpace.GLOSSARY,
        NameSpace.NAVIGATION,
        NameSpace.ROUTES,
        NameSpace.PAGES,
    ]);
    
    const noContent = t("noContent", { ns: NameSpace.PAGES });

    const language = Util.splitLanguage(i18n.language, 0);

    useEffect(() => {

        if(mounted){
           OpportunityService.getAllGroupedOpportunities(language).then((res) =>{
             setOpporunitiesByGamaList(res.data);
             console.log("lista agrupada: ", res.data)
           })
        }      
       
        return() => {
            setMounted(false);
        };
    },[language]);

   
return (
    <>
        <div>

            {opporunitiesByGamaList.length ? (
                <Container maxWidth="xl" className="" sx={{ my: 12}}>

                    <Typogr.Typography component="h1" variant="h3" align="left" fontFamily={'Roboto'}>
                        {t("opportunities", { ns: NameSpace.NAVIGATION })}
                    </Typogr.Typography>     

                    <hr />
                    <button className="btn btn-info mb-3" onClick={() => history.goBack()}><MdArrowBack className="variant-wrapper-tabs-button-prev-icon" /></button>
                   
                    {opporunitiesByGamaList?.map((item, key) => {
                      return (
                        <Box sx={{ mt: 4 }}>

                          <Typogr.Typography component={'h2'} variant={'h4'} display={'flex'} sx={{ alignItems: 'center'}}>
                            {item.title}

                            <Link to={`${t("opportunity", { ns: NameSpace.ROUTES })}/${item.gammaId}`} >

                              <Typogr.Typography  variant={'h6'} marginLeft={10}  sx={{ alignItems: 'center'}} color={'#003aa8'}>
                                Ver todos
                                  <OpenInNewIcon sx={{ alignItems: 'center'}}/>
                              </Typogr.Typography>

                            </Link>                            
                          </Typogr.Typography>           


                          <Grid container      
                            sx={{ pt: 4, pb: 4}}                   
                            key={key}                                        
                          >       
                            {item.opportunitiesCollection?.slice(0,4).map((opportunity, index) => (
                                <Grid item
                                xl={3}
                                lg={4}
                                md={4}
                                sm={6}
                                xs={12}
                                key={index}
                                >                                  
                                  <Link to={`/${language}/${t("opportunity", {ns: NameSpace.ROUTES,})}/opportunityitem/${opportunity.id}`}>           

                                    <Card sx={{ width: 345, backgroundColor: '#ffffff', border: 'none' , marginBottom: 1}}>
                                      <div>
                                        <Typography level="title-lg">{opportunity.opportunittyTranslations[0]?.title}</Typography>
                                        <Typography level="body-sm">{opportunity.reference ? `Ref: ${opportunity.reference}` : `Ref: -`}</Typography>                                         
                                      </div>

                                      <AspectRatio minHeight="200px" maxHeight="200px">
                                        <img
                                          src={opportunity?.opportunityMediaCollection[0]?.name ?? ""}
                                          srcSet={opportunity?.opportunityMediaCollection[0]?.name ?? ""}
                                          loading="lazy"
                                          alt="image"
                                        />
                                        {/* <img
                                          src={"https://source.unsplash.com/random?=3"}
                                          srcSet={"https://source.unsplash.com/random?=3"}
                                          loading="lazy"
                                          alt="image"
                                        /> */}
                                      </AspectRatio>

                                      <CardContent orientation="horizontal">
                                        <Box>
                                          <Typography level="body-sm">
                                            {t("totalPrice", { ns: NameSpace.NAVIGATION })}
                                          </Typography>                    

                                          <Box display={'flex'}>
                                            <Typogr.Typography variant="h6" color="text.secondary" sx={{ textDecoration: "line-through"}}>
                                            {opportunity.previousPrice ? `€ ${opportunity.previousPrice}` : ""} 
                                            </Typogr.Typography>
                                            
                                            <Typogr.Typography variant="h6" color="text.secondary" sx={{ marginLeft: Number(opportunity.previousPrice) !== 0  ? 2 : 0}}>
                                            € {opportunity.currentPrice}
                                            </Typogr.Typography>
                                          </Box>       
                                           
                                        </Box>
                                      </CardContent>
                                      
                                      <CardActions disableSpacing>
                                        <Typogr.Typography component={'h6'} variant="h6" aria-label="add to favorites" color="text.secondary">
                                            {t("knowMore", { ns: NameSpace.NAVIGATION})}
                                        </Typogr.Typography>

                                        <IconButton aria-label="know-more">                                        
                                          <ControlPointIcon />
                                        </IconButton>                                      
                                      </CardActions>
                                    </Card>
                                        
                                  </Link>  
                                </Grid>
                              ))}
                          </Grid>
                        </Box>                       
                      );
                    })}                                 
             </Container>
            ) : (
                <Container maxWidth="xl" className="mt-10" style={{height: '100vh'}}>
                  
                    <Typogr.Typography component="h1" variant="h3" align="left">
                        {t("opportunities", { ns: NameSpace.NAVIGATION })}
                    </Typogr.Typography>     

                    <hr  />
                    
                    <Alert variant="warning" className="category-wrapper-no-content text-center mt-5">
                        <Typogr.Typography component="h6" variant="h6" align="center">
                            {noContent}
                        </Typogr.Typography>                          
                    </Alert>
                    
                </Container>                
            )}
             
        </div>
    </>
);
}

export default Opportunities;

const opportunities: OpportunityGammaModel[] = 
[
  {
    gammaId: "sasfa51561",
    title: "Equipamentos de Refrigeração",
    opportunitiesCollection: 
    [
      {
        id: "qwasfsa",
        gammaId: "sasfa51561",
        code: 0,
        currentPrice: "2500",
        previousPrice: "",
        position: 3,
        reference: "MIR-LGH25",
        opportunittyTranslations: 
        [
          {
            opportunityId: "qwasfsa",
            title: "Semi Mural Alfa 1",
            subtitle: "",
            languageId: "PT",
            description: "Disponível em quatro comprimentos: 1250, 1875, 2500 e 3750, três profundidades: 750, 850 e 950 e em duas alturas: 2050 e 2250 Mural de frente baixa para ampla visão do produto exposto."            
          }
        ],
        opportunityMediaCollection: 
        [
          {
            path: "https://mm.mafirol.info/Categories/Subfamilies/20230109150236132_7c7c74eb-59f2-4c54-aa41-332dc034f3ea.png",
            type: MediaType.IMAGE,
            caption: "",
            position: "1",
          },
          {
            path: "https://mm.mafirol.info/Categories/Subfamilies/20230120091316597_9f44c6f3-889d-483f-8dd2-2c9ab96550f5.png",
            type: MediaType.IMAGE,
            caption: "",
            position: "2",
          },
          {
            path: "https://source.unsplash.com/random?=3",
            type: MediaType.IMAGE,
            caption: "",
            position: "4",
          },
          {
            path: "https://source.unsplash.com/random?=4",
            type: MediaType.IMAGE,
            caption: "",
            position: "5",
          },
          {
            path: "https://source.unsplash.com/random?=5",
            type: MediaType.IMAGE,
            caption: "",
            position: "3",
          }
        ]
      }, {
        id: "fdhdfhj",
        gammaId: "sasfa51561",
        code: 0,
        currentPrice: "3000",
        previousPrice: "3200",
        position: 3,
        reference: "MIR-LGA55",
        opportunittyTranslations: 
        [
          {
            opportunityId: "qwasfsa",
            title: "Mural Alfa 1",
            subtitle: "",
            description: "Disponível em quatro comprimentos: 1250, 1875, 2500 e 3750, três profundidades: 750, 850 e 950 e em duas alturas: 2050 e 2250 Mural de frente baixa para ampla visão do produto exposto."            
          }
        ],
        opportunityMediaCollection: 
        [
          {
            path: "https://mm.mafirol.info/Categories/Subfamilies/20230120091316597_9f44c6f3-889d-483f-8dd2-2c9ab96550f5.png",
            type: MediaType.IMAGE,
            caption: "",
            position: "1",
          },
          {
            path: "https://source.unsplash.com/random?=7",
            type: MediaType.IMAGE,
            caption: "",
            position: "2",
          },
          {
            path: "https://source.unsplash.com/random?=8",
            type: MediaType.IMAGE,
            caption: "",
            position: "4",
          },
          {
            path: "https://source.unsplash.com/random?=9",
            type: MediaType.IMAGE,
            caption: "",
            position: "5",
          },
          {
            path: "https://source.unsplash.com/random?=10",
            type: MediaType.IMAGE,
            caption: "",
            position: "3",
          }
        ]
      },
      {
        id: "qwasfsa",
        gammaId: "sasfa51561",
        code: 0,
        currentPrice: "2500",
        previousPrice: "3200",
        position: 3,
        reference: "",
        opportunittyTranslations: 
        [
          {
            opportunityId: "qwasfsa",
            title: "Semi Mural Alfa 1",
            subtitle: "",
            description: "Disponível em quatro comprimentos: 1250, 1875, 2500 e 3750, três profundidades: 750, 850 e 950 e em duas alturas: 2050 e 2250 Mural de frente baixa para ampla visão do produto exposto."            
          }
        ],
        opportunityMediaCollection: 
        [
          {
            path: "https://source.unsplash.com/random?=11",
            type: MediaType.IMAGE,
            caption: "",
            position: "1",
          },
          {
            path: "https://source.unsplash.com/random?=12",
            type: MediaType.IMAGE,
            caption: "",
            position: "2",
          },
          {
            path: "https://source.unsplash.com/random?=13",
            type: MediaType.IMAGE,
            caption: "",
            position: "4",
          },
          {
            path: "https://source.unsplash.com/random?=14",
            type: MediaType.IMAGE,
            caption: "",
            position: "5",
          },
          {
            path: "https://source.unsplash.com/random?=15",
            type: MediaType.IMAGE,
            caption: "",
            position: "3",
          }
        ]
      }, 
      {
        id: "qwasfsa",
        gammaId: "sasfa51561",
        code: 0,
        currentPrice: "2500",
        previousPrice: "3200",
        position: 3,
        reference: "MIR-LGH25",
        opportunittyTranslations: 
        [
          {
            opportunityId: "qwasfsa",
            title: "Semi Mural Alfa 1",
            subtitle: "",
            description: "Disponível em quatro comprimentos: 1250, 1875, 2500 e 3750, três profundidades: 750, 850 e 950 e em duas alturas: 2050 e 2250 Mural de frente baixa para ampla visão do produto exposto."            
          }
        ],
        opportunityMediaCollection: 
        [
          {
            path: "https://source.unsplash.com/random?=16",
            type: MediaType.IMAGE,
            caption: "",
            position: "1",
          },
          {
            path: "https://source.unsplash.com/random?=17",
            type: MediaType.IMAGE,
            caption: "",
            position: "2",
          },
          {
            path: "https://source.unsplash.com/random?=18",
            type: MediaType.IMAGE,
            caption: "",
            position: "4",
          },
          {
            path: "https://source.unsplash.com/random?=19",
            type: MediaType.IMAGE,
            caption: "",
            position: "5",
          },
          {
            path: "https://source.unsplash.com/random?=20",
            type: MediaType.IMAGE,
            caption: "",
            position: "3",
          }
        ]
      },
      {
        id: "qwasfsa",
        gammaId: "sasfa51561",
        code: 0,
        currentPrice: "2500",
        previousPrice: "",
        position: 3,
        reference: "MIR-LGH25",
        opportunittyTranslations: 
        [
          {
            opportunityId: "qwasfsa",
            title: "Semi Mural Ômega 2",
            subtitle: "",
            description: "Disponível em quatro comprimentos: 1250, 1875, 2500 e 3750, três profundidades: 750, 850 e 950 e em duas alturas: 2050 e 2250 Mural de frente baixa para ampla visão do produto exposto."            
          }
        ],
        opportunityMediaCollection: 
        [
          {
            path: "https://mm.mafirol.info/Categories/Subfamilies/20230109150236132_7c7c74eb-59f2-4c54-aa41-332dc034f3ea.png",
            type: MediaType.IMAGE,
            caption: "",
            position: "1",
          },
          {
            path: "https://mm.mafirol.info/Categories/Subfamilies/20230120091316597_9f44c6f3-889d-483f-8dd2-2c9ab96550f5.png",
            type: MediaType.IMAGE,
            caption: "",
            position: "2",
          },
          {
            path: "https://source.unsplash.com/random?=3",
            type: MediaType.IMAGE,
            caption: "",
            position: "4",
          },
          {
            path: "https://source.unsplash.com/random?=4",
            type: MediaType.IMAGE,
            caption: "",
            position: "5",
          },
          {
            path: "https://source.unsplash.com/random?=5",
            type: MediaType.IMAGE,
            caption: "",
            position: "3",
          }
        ]
      },
      {
        id: "qwasfsa",
        gammaId: "sasfa51561",
        code: 0,
        currentPrice: "2500",
        previousPrice: "",
        position: 3,
        reference: "MIR-LGH25",
        opportunittyTranslations: 
        [
          {
            opportunityId: "qwasfsa",
            title: "Teste Mural Ômega 2",
            subtitle: "",
            description: "Disponível em quatro comprimentos: 1250, 1875, 2500 e 3750, três profundidades: 750, 850 e 950 e em duas alturas: 2050 e 2250 Mural de frente baixa para ampla visão do produto exposto."            
          }
        ],
        opportunityMediaCollection: 
        [
          {
            path: "https://mm.mafirol.info/Categories/Subfamilies/20230109150236132_7c7c74eb-59f2-4c54-aa41-332dc034f3ea.png",
            type: MediaType.IMAGE,
            caption: "",
            position: "1",
          },
          {
            path: "https://mm.mafirol.info/Categories/Subfamilies/20230120091316597_9f44c6f3-889d-483f-8dd2-2c9ab96550f5.png",
            type: MediaType.IMAGE,
            caption: "",
            position: "2",
          },
          {
            path: "https://source.unsplash.com/random?=3",
            type: MediaType.IMAGE,
            caption: "",
            position: "4",
          },
          {
            path: "https://source.unsplash.com/random?=4",
            type: MediaType.IMAGE,
            caption: "",
            position: "5",
          },
          {
            path: "https://source.unsplash.com/random?=5",
            type: MediaType.IMAGE,
            caption: "",
            position: "3",
          }
        ]
      }
    ]
  }, 
  {
    gammaId: "dsgds3554",
    title: "Estanteria e Pastelaria",
    opportunitiesCollection: 
    [
      {
        id: "qwasfsa",
        gammaId: "dsgds3554",
        code: 0,
        currentPrice: "2500",
        previousPrice: "3200",
        position: 3,
        reference: "MIR-LGH25",
        opportunittyTranslations: 
        [
          {
            opportunityId: "qwasfsa",
            title: "Semi Mural Alfa 1",
            subtitle: "",
            description: "Disponível em quatro comprimentos: 1250, 1875, 2500 e 3750, três profundidades: 750, 850 e 950 e em duas alturas: 2050 e 2250 Mural de frente baixa para ampla visão do produto exposto."            
          }
        ],
        opportunityMediaCollection: 
        [
          {
            path: "https://mm.mafirol.info/Categories/Subfamilies/20230120091316597_9f44c6f3-889d-483f-8dd2-2c9ab96550f5.png",
            type: MediaType.IMAGE,
            caption: "",
            position: "1",
          },
          {
            path: "https://source.unsplash.com/random?=22",
            type: MediaType.IMAGE,
            caption: "",
            position: "2",
          },
          {
            path: "https://source.unsplash.com/random?=23",
            type: MediaType.IMAGE,
            caption: "",
            position: "4",
          },
          {
            path: "https://source.unsplash.com/random?=24",
            type: MediaType.IMAGE,
            caption: "",
            position: "5",
          },
          {
            path: "https://source.unsplash.com/random?=25",
            type: MediaType.IMAGE,
            caption: "",
            position: "3",
          }
        ]
      }, {
        id: "fdhdfhj",
        gammaId: "dsgds3554",
        code: 0,
        currentPrice: "3000",
        previousPrice: "3200",
        position: 3,
        reference: "MIR-LGA55",
        opportunittyTranslations: 
        [
          {
            opportunityId: "qwasfsa",
            title: "Semi Mural Alfa 1",
            subtitle: "",
            description: "Disponível em quatro comprimentos: 1250, 1875, 2500 e 3750, três profundidades: 750, 850 e 950 e em duas alturas: 2050 e 2250 Mural de frente baixa para ampla visão do produto exposto."            
          }
        ],
        opportunityMediaCollection: 
        [
          {
            path: "https://source.unsplash.com/random?=26",
            type: MediaType.IMAGE,
            caption: "",
            position: "1",
          },
          {
            path: "https://source.unsplash.com/random?=27",
            type: MediaType.IMAGE,
            caption: "",
            position: "2",
          },
          {
            path: "https://source.unsplash.com/random?=28",
            type: MediaType.IMAGE,
            caption: "",
            position: "4",
          },
          {
            path: "https://source.unsplash.com/random?=29",
            type: MediaType.IMAGE,
            caption: "",
            position: "5",
          },
          {
            path: "https://source.unsplash.com/random?=30",
            type: MediaType.IMAGE,
            caption: "",
            position: "3",
          }
        ]
      },
      {
        id: "qwasfsa",
        gammaId: "dsgds3554",
        code: 0,
        currentPrice: "2500",
        previousPrice: "3200",
        position: 3,
        reference: "MIR-LGH25",
        opportunittyTranslations: 
        [
          {
            opportunityId: "qwasfsa",
            title: "Semi Mural Alfa 1",
            subtitle: "",
            description: "Disponível em quatro comprimentos: 1250, 1875, 2500 e 3750, três profundidades: 750, 850 e 950 e em duas alturas: 2050 e 2250 Mural de frente baixa para ampla visão do produto exposto."            
          }
        ],
        opportunityMediaCollection: 
        [
          {
            path: "https://source.unsplash.com/random?=31",
            type: MediaType.IMAGE,
            caption: "",
            position: "1",
          },
          {
            path: "https://source.unsplash.com/random?=32",
            type: MediaType.IMAGE,
            caption: "",
            position: "2",
          },
          {
            path: "https://source.unsplash.com/random?=33",
            type: MediaType.IMAGE,
            caption: "",
            position: "4",
          },
          {
            path: "https://source.unsplash.com/random?=34",
            type: MediaType.IMAGE,
            caption: "",
            position: "5",
          },
          {
            path: "https://source.unsplash.com/random?=35",
            type: MediaType.IMAGE,
            caption: "",
            position: "3",
          }
        ]
      }, 
      {
        id: "qwasfsa",
        gammaId: "dsgds3554",
        code: 0,
        currentPrice: "2500",
        previousPrice: "3200",
        position: 3,
        reference: "MIR-LGH25",
        opportunittyTranslations: 
        [
          {
            opportunityId: "qwasfsa",
            title: "Semi Mural Alfa 1",
            subtitle: "",
            description: "Disponível em quatro comprimentos: 1250, 1875, 2500 e 3750, três profundidades: 750, 850 e 950 e em duas alturas: 2050 e 2250 Mural de frente baixa para ampla visão do produto exposto."            
          }
        ],
        opportunityMediaCollection: 
        [
          {
            path: "https://source.unsplash.com/random?=36",
            type: MediaType.IMAGE,
            caption: "",
            position: "1",
          },
          {
            path: "https://source.unsplash.com/random?=37",
            type: MediaType.IMAGE,
            caption: "",
            position: "2",
          },
          {
            path: "https://source.unsplash.com/random?=38",
            type: MediaType.IMAGE,
            caption: "",
            position: "4",
          },
          {
            path: "https://source.unsplash.com/random?=39",
            type: MediaType.IMAGE,
            caption: "",
            position: "5",
          },
          {
            path: "https://source.unsplash.com/random?=40",
            type: MediaType.IMAGE,
            caption: "",
            position: "3",
          }
        ]
      },
      {
        id: "qwasfsa",
        gammaId: "sasfa51561",
        code: 0,
        currentPrice: "2500",
        previousPrice: "",
        position: 3,
        reference: "MIR-LGH25",
        opportunittyTranslations: 
        [
          {
            opportunityId: "qwasfsa",
            title: "Semi Mural Ômega 2",
            subtitle: "",
            description: "Disponível em quatro comprimentos: 1250, 1875, 2500 e 3750, três profundidades: 750, 850 e 950 e em duas alturas: 2050 e 2250 Mural de frente baixa para ampla visão do produto exposto."            
          }
        ],
        opportunityMediaCollection: 
        [
          {
            path: "https://mm.mafirol.info/Categories/Subfamilies/20230109150236132_7c7c74eb-59f2-4c54-aa41-332dc034f3ea.png",
            type: MediaType.IMAGE,
            caption: "",
            position: "1",
          },
          {
            path: "https://mm.mafirol.info/Categories/Subfamilies/20230120091316597_9f44c6f3-889d-483f-8dd2-2c9ab96550f5.png",
            type: MediaType.IMAGE,
            caption: "",
            position: "2",
          },
          {
            path: "https://source.unsplash.com/random?=3",
            type: MediaType.IMAGE,
            caption: "",
            position: "4",
          },
          {
            path: "https://source.unsplash.com/random?=4",
            type: MediaType.IMAGE,
            caption: "",
            position: "5",
          },
          {
            path: "https://source.unsplash.com/random?=5",
            type: MediaType.IMAGE,
            caption: "",
            position: "3",
          }
        ]
      },
      {
        id: "qwasfsa",
        gammaId: "sasfa51561",
        code: 0,
        currentPrice: "2500",
        previousPrice: "",
        position: 3,
        reference: "MIR-LGH25",
        opportunittyTranslations: 
        [
          {
            opportunityId: "qwasfsa",
            title: "Teste Mural Ômega 2",
            subtitle: "",
            description: "Disponível em quatro comprimentos: 1250, 1875, 2500 e 3750, três profundidades: 750, 850 e 950 e em duas alturas: 2050 e 2250 Mural de frente baixa para ampla visão do produto exposto."            
          }
        ],
        opportunityMediaCollection: 
        [
          {
            path: "https://mm.mafirol.info/Categories/Subfamilies/20230109150236132_7c7c74eb-59f2-4c54-aa41-332dc034f3ea.png",
            type: MediaType.IMAGE,
            caption: "",
            position: "1",
          },
          {
            path: "https://mm.mafirol.info/Categories/Subfamilies/20230120091316597_9f44c6f3-889d-483f-8dd2-2c9ab96550f5.png",
            type: MediaType.IMAGE,
            caption: "",
            position: "2",
          },
          {
            path: "https://source.unsplash.com/random?=3",
            type: MediaType.IMAGE,
            caption: "",
            position: "4",
          },
          {
            path: "https://source.unsplash.com/random?=4",
            type: MediaType.IMAGE,
            caption: "",
            position: "5",
          },
          {
            path: "https://source.unsplash.com/random?=5",
            type: MediaType.IMAGE,
            caption: "",
            position: "3",
          }
        ]
      }
    ]
  }, 
]