import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { ContentPageModel } from "../../models/ContentPageModel";

import { ContentPageType } from "../../enums/ContentPageType";

import Util from "../../utils/Util";

import { NameSpace } from "../../data/AppLanguage";

import ContentPageService from "../../services/api/ContentPage";

import ContactPhoneForm from "../../components/ContactPhoneForm/ContactPhoneForm";
import BreadcrumbPath from "../../components/BreadcrumbPath/BreadcrumbPath";
import Title from "../../components/Title/Title";
import Description from "../../components/Description/Description";

import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState<ContentPageModel>();

  const { t, i18n } = useTranslation([NameSpace.GLOSSARY]);
  const language = Util.splitLanguage(i18n.language, 0);
  const privacyPolicyTitle = t("privacyPolicy");

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      document.title = Util.setDocumentTitle(privacyPolicyTitle);

      ContentPageService.getContentPages(
        ContentPageType.PRIVACYPOLICY,
        language
      ).then((res) => {
        setPrivacyPolicy(res.data);
      });
    }

    return () => {
      mounted = false;
    };
  }, [privacyPolicyTitle, language]);

  return (
    <>
      <ContactPhoneForm />
      <BreadcrumbPath current={privacyPolicyTitle} className="pt-8" />
      <article className="privacy-policy mb-5">
        <Container>
          <Row className="privacy-policy-wrapper">
            <Col>
              <Title
                title={privacyPolicy?.title}
                className="title-title-privacy-policy"
              />
            </Col>
          </Row>
          <Row className="mt-4">
            {privacyPolicy?.body ? (
              <Col>
                <Description
                  text={privacyPolicy.body}
                  className="description-privacy-policy"
                />
              </Col>
            ) : null}
          </Row>
        </Container>
      </article>
    </>
  );
};

export default PrivacyPolicy;
