import DataSheetPDFGenerator from "../../components/DataSheetPDfGenerator/DataSheetPDFGenerator";
import ContactSpecificForm from "../../components/ContactSpecificForm/ContactSpecificForm";
import MediaViewer from "../../components/MediaViewer/MediaViewer";
import CarouselDataSheet from "../../components/CarouselDataSheet/CarouselDataSheet";
import BreadcrumbPath from "../../components/BreadcrumbPath/BreadcrumbPath";

import SubfamilyDataSheetModel from "../../models/SubfamilyDataSheetModel";
import DataSheetFeatureWithTranslation from "../../models/DataSheetFeatureWithTranslation";
import DataSheetModelResponse from "../../models/DataSheetModelResponse";

import DataSheetService from "../../services/api/DataSheetService";
import Gamma from "../../services/api/Gamma";
import Category from "../../services/api/Category";
import Util from "../../utils/Util";

import { Box, Button, CircularProgress, Container, Grid, IconButton, Typography } from "@mui/material";

import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { NameSpace , contactForm } from "../../data/AppLanguage";
import { useTranslation } from "react-i18next";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import toast, { Toaster } from 'react-hot-toast';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import style from './SubfamilyDataSheet.module.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-multi-carousel/lib/styles.css";
import "./SubfamilyDataSheet.css";
import Slider from "react-slick";


interface Settings {
    dots: boolean,
    speed: number,
    slidesToShow: number,
    slidesToScroll: number,
    infinite: boolean,
    autoplay: boolean,
    autoplaySpeed: number,
    prevArrow: any,
    nextArrow: any,
    responsive: any,
    className: "center",
    centerMode: true,
    centerPadding: any,
    rows: any,
    slidesPerRow: any
}


const SubfamilyDesktop = () => {
    const params = useParams();
    const { subfamily } = params;    
    const dataSheetId = subfamily;
    const { gamma, line, family } = params;
    
    const [gammaName, setGammaName] = useState<string>("");
    const [lineName, setLineName] = useState<string>("");
    const [familyName, setFamilyName] = useState<string>("");

    const [subfamilyDataSheet, setSubfamilyDataSheet] = useState<SubfamilyDataSheetModel>();        
    const [imageSelected, setImageSelected] = useState<string>(subfamilyDataSheet?.subfamilyMediaUrl ?? "");
    const [selectedItemIndex, setSelectedItemIndex ] = useState<number>(0);                
    const [hasTable, setHasTable] = useState<boolean>(false);
    const [listImages, setListImages] = useState<{ src: string }[]>([]);    
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasCodeDataSheet, setHasCodeDataSheet] = useState<string>('');

    const { t, i18n } = useTranslation([ NameSpace.ROUTES ]);
    const language = Util.splitLanguage(i18n.language, 0);
    const contactFormRef = useRef<HTMLElement>(null);    

    const [visible, setVisible] = useState(false);
    const [activeImageIndex, setActiveImageIndex] = useState(0);
    
    var images: { src: string }[] = [];    

    const askInformation = () => {
        if (contactFormRef.current) {
          contactFormRef.current.scrollIntoView({
            block: "start",
            behavior: "smooth",
          });
        }
    };
  

    useEffect(() => {
        Gamma.getGammas(language).then((res) => {
            var result = res.data;
            const gammaNameResult = result.find(item => item.path === gamma)?.name;
            setGammaName(gammaNameResult ?? "");
        })

        if(gammaName !== ""){
            Category.getLineFamilies(gamma, line, language).then((res) => {                
                var result = res.data;
                setLineName(result.current.name);
                const familyNameResult = result.successors.find(item => item.path === family)?.name;
                setFamilyName(familyNameResult ?? "");
            })
        }
    }, [gammaName]);

    const handleImageChange = (url?: string, colorId?: number, index?: number, data?: any, languages?: string, table?: DataSheetFeatureWithTranslation[]) => {
        if(url) setImageSelected(url);
        if(index !== undefined) setSelectedItemIndex(index);

        if(index !== undefined) setActiveImageIndex(index);
                  
        if(table && table.length > 0) {            
            setTimeout(() => {
                setHasTable(true);
                buildingTable(table, language.toLocaleUpperCase());      
            },500)
        }
        else {
            setHasTable(false);
        }
    };
  

    const openImageViewer = () => {
        setVisible(true);
    };

    const closeImageViewer = () => {
       setVisible(false);
    }


    useEffect(() => {
        if(dataSheetId){
            DataSheetService.getSubfamilyDataSheet(dataSheetId, language.toLocaleUpperCase()).then((res) => {                
                const result  = res.data;
                setSubfamilyDataSheet(res.data);
                
                if(result.subfamilyMediaUrl){
                    setImageSelected(result.subfamilyMediaUrl);
                    images.push({ src: result?.subfamilyMediaUrl ?? "" });                    
                }
                if(result.dataSheets){
                    result.dataSheets.forEach(item => {
                        if(item.urlImgCut) images.push({ src: item.urlImgCut ?? ""})
                    })
                }
                setListImages(images);
            }).catch(err => err)
        }
    },[subfamily])

    const NextArrow = ({ onClick }: { onClick: () => void }) => (
        <IconButton onClick={onClick} size="large" sx={{ position: 'absolute', top: '50%', right: -25, transform: 'translateY(-50%)' }}>
          <MdKeyboardArrowRight />
        </IconButton>
      );

      const PrevArrow = ({ onClick }: { onClick: () => void }) => (
        <IconButton onClick={onClick} size="large" sx={{ position: 'absolute', top: '50%', left: -25, transform: 'translateY(-50%)' }}>
          <MdKeyboardArrowLeft />
        </IconButton>
      );

      const settings: Settings = {
        dots: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 1000,
        nextArrow: <NextArrow />,

        prevArrow: <PrevArrow />,

        className: "center",
        // centerMode: true,
        // centerPadding: "60px",
        rows: 1,
        // rows: 2,
        // slidesPerRow: 2,

        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };

    const generatePdf = async (dataSheetItem: DataSheetModelResponse) => {
        toast.success(`${t(`${contactForm}loading`, {ns: NameSpace.COMMON })}`, {
            position: 'bottom-left',
            duration: 20000,
            icon:  <CircularProgress sx={{ color: "#ffffff" }} />,
            style: {
                minWidth: '450px',
                minHeight: '50px',
                color: "#ffffff",
                backgroundColor: "#50C878",
                fontSize: "20px"
                },      
            });      

        if(dataSheetItem)
          {        
                DataSheetPDFGenerator(dataSheetItem, language);
                setIsLoading(false);  
          }
      };


    return (
        <>
         <Container maxWidth={"1200px"}  sx={{ maxWidth: "1200px" , height: "auto" , paddingTop: 12, paddingBottom: 15}}>
            <BreadcrumbPath
                ancestors={[
                {
                    name: gammaName,
                    path: gamma,
                },
                {
                    name: lineName,
                    path: line,
                },
                {
                    name: familyName,
                    path: family,
                },
                ]}
                className="pt-1"
                current={subfamily}                                                    
            />  
            <Box className="container1" display={'flex'} width={'100%'}>
                <Box className="sibiling1" width={'60%'}>
                    <>                                                 
                            <Typography component={'h2'} variant="h6" sx={{ textTransform: "uppercase" , color: "#8B939F", mb: 2}}>
                                {subfamilyDataSheet?.familyName}
                            </Typography>
                            <Typography
                                className="title"
                                component={'h1'}
                                variant={'h1'}
                                sx={{
                                textTransform: "uppercase" ,
                                color: "#28487A",
                                fontSize: "25px",
                                fontWeight: 700
                                }}
                            >
                                {subfamilyDataSheet?.subfamilyName}
                            </Typography>

                            <hr style={{ margin: 0, padding: 0 }}/>
                            <Box display={'flex'} justifyContent={'center'} paddingY={3}>
                                <img
                                    src={imageSelected}
                                    alt=""
                                    style={{ width: 400, maxWidth: "450px", cursor: "pointer"}}
                                    onClick={() => openImageViewer()}

                                />
                            </Box>

                                                    
                  
                            {subfamilyDataSheet?.dataSheets?.map((item, index) => (

                                selectedItemIndex === (index + 1) ? (
                                <>
                                 <div className={`${style.cardContainer}`}>
                                            {item && item?.listColor1 != 0 && item?.listColor1 && 
                                            <>                                           
                                                <div className="row mb-5">
                                                        {item.listColor1 && item.listColor1.map((item, index) => {
                                                            return(
                                                                <div  className={style.cardColor}>
                                                                    <img 
                                                                    key={index}
                                                                    className={style.imageColor}
                                                                    src={item} alt="image" />
                                                                </div>
                                                            );
                                                            
                                                        })}                            
                                                </div>
                                                {/* <hr className={style.hr}/> */}
                                            </>                             
                                            }
                                        
                                </div>            
                                 <Box marginTop={5} justifyContent={'center'} display={'flex'}> 
                                 
                                        <Button
                                        className=""
                                        startIcon={isLoading ? <CircularProgress sx={{ color: "#ffffff" }}/> : <FileDownloadOutlinedIcon />}                                                               
                                        onClick={() =>  { 
                                            setIsLoading(true);
                                            setTimeout(() => {
                                                generatePdf(item);
                                            },1)
                                        }}                                    
                                        sx={{ 
                                            color: "green",
                                            backgroundColor: "#ffffff",
                                            border: "1px solid green",
                                            borderRadius: 1,                                                                                        
                                            margin: "20px",
                                            ":hover": { color: "white", backgroundColor: "green" }
                                        }}
                                        >                                
                                            <Typography>
                                                { isLoading ? t(`${contactForm}loading`, {ns: NameSpace.COMMON }) : t("dataSheets", {ns: NameSpace.PAGES }) }
                                            </Typography>
                                        </Button> 
                                  
                                                                      
                                  
                                    <Button
                                        startIcon={<AddIcon sx={{ ":hover" : { color: "#ffffff"}}}/>}
                                        onClick={() => askInformation()}
                                        sx={{ 
                                            color: "#28487A",
                                            backgroundColor: "#ffffff",
                                            border: "1px solid #28487A",
                                            borderRadius: 1,
                                            margin: "20px 0",
                                            ":hover": { color: "white", backgroundColor: "#28487A" }
                                        }}
                                        >
                                            <Typography>
                                                {t("knowMore", { ns: NameSpace.NAVIGATION })}
                                            </Typography>
                                        </Button> 
                                </Box>
                               
                                </>
                                ) :  null
                            ))}
                    </>
                </Box>
                <Box className="sibiling2" width={'50%'}>
                    <Box display={''} alignItems={"center"}>
                            <Box sx={{  maxHeight: '80px !important'}}>
                                <Typography component={'p'} variant="body2" sx={{ fontSize: "18px", textTransform: "uppercase"}} align="center">
                                        {t("selectModel", {ns: NameSpace.NAVIGATION }) }
                                </Typography>
                                <div className="swiper-arrow-subfamily-root">
                                    <div className="swiper-arrow-subfamily">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </Box>
                            <CarouselDataSheet
                              subfamilyDataSheet={subfamilyDataSheet}
                              setHasCodeDataSheet={setHasCodeDataSheet}
                              handleImageChange={handleImageChange}                              
                             />
                            {/* <Slider {...settings} style={{ paddingLeft: 23 }}>
                                { subfamilyDataSheet?.dataSheets?.map((dataSheet, index) => (
                                    subfamilyDataSheet.dataSheets?.length && subfamilyDataSheet.dataSheets?.length >= 3 && dataSheet.urlImgCut &&
                                    <Box key={index + 1}  className={`${(index + 1) === selectedItemIndex ? '' : 'img-body_none'}`}
                                    textAlign={'center'}
                                    >                                        
                                        <Box
                                            className={`img-body ${(index + 1) === selectedItemIndex ? 'selected' : ''}`}
                                            key={index + 1}>
                                            <img src={dataSheet?.urlImgCut != "" ? dataSheet.urlImgCut : "https://mm.mafirol.info/empty.png"} alt="" style={{ cursor: "pointer" }}
                                            key={index + 1}                                            
                                            onClick={() => {
                                                handleImageChange(dataSheet?.urlImgCut, undefined, index + 1, dataSheet?.dataTable, dataSheet?.language, dataSheet?.dataTable)
                                                setHasCodeDataSheet(dataSheet?.code ?? "");
                                            }}
                                            />
                                        </Box>
                                        <Box key={index + 1}>
                                            <Typography component={'h2'} variant={'h6'} fontSize={15} key={index + 1}>
                                                {dataSheet?.subtitleText}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    )
                                )}                            
                            </Slider>  */}
                            {/* <Grid container gap={4}>
                                { subfamilyDataSheet?.dataSheets?.map((dataSheet, index) => (
                                    subfamilyDataSheet.dataSheets?.length && subfamilyDataSheet.dataSheets?.length <= 3 ? (
                                        <Grid item key={index} textAlign={'center'}>
                                            <Box key={index} className={`${(index + 1) === selectedItemIndex ? '' : 'img-body_none'}`}>
                                                <Box
                                                className={`img-body ${(index +1 ) === selectedItemIndex  ? 'selected' : ''}`} key={index} >
                                                    <img src={dataSheet?.urlImgCut != "" ?dataSheet.urlImgCut : "https://mm.mafirol.info/empty.png"} alt="" style={{ cursor: "pointer" }}
                                                    key={index}
                                                    onClick={() =>  {
                                                        handleImageChange(dataSheet?.urlImgCut, undefined, index + 1, dataSheet?.dataTable, dataSheet?.language, dataSheet?.dataTable)
                                                        setHasCodeDataSheet(dataSheet?.code ?? "")
                                                    }}
                                                    />
                                                </Box>
                                                <Box key={index}>
                                                    <Typography component={'h2'} variant={'h6'} fontSize={15} key={index}>
                                                        {dataSheet?.subtitleText}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                         </Grid>                                        
                                    ) : null
                                    )
                                )}
                            </Grid> */}
                            <Box display="flex" flexDirection="column" className="thumbnailContainer" >
                            {
                                hasCodeDataSheet !== '' && 
                                <Typography component={'span'} variant={"body2"} sx={{ fontSize: "10px", color: 'gray',  margin: "20px 0 0 10px"}}>
                                    Ref : {hasCodeDataSheet ?? ""}
                                </Typography>
                            }   
                            {subfamilyDataSheet?.subfamilyMediaUrl &&
                                <div  className={''} onClick={() => handleImageChange(subfamilyDataSheet?.subfamilyMediaUrl ?? "", undefined, 0)} >
                                    <img src={subfamilyDataSheet?.subfamilyMediaUrl} alt="image main"  className={`${imageSelected === subfamilyDataSheet.subfamilyMediaUrl ? 'thumbnailImage' : 'thumbnail'}`} />
                                </div>
                            }
                            <Typography fontSize={11} sx={{ color: '#878787', my:2 }} align="center">* {t("nonContractualImage", { ns: NameSpace.NAVIGATION})}</Typography>
                            </Box>
                                                     
                        </Box>
                    </Box>
                </Box>
            <hr />
            <Box className="contentProduct" width={'100%'}>                   
                <Box className="product-info" display={'block'}>
                    <Box className="product-header">
                    {
                        <>
                            {subfamilyDataSheet?.dataSheets?.map((item, index) => (

                                selectedItemIndex === (index + 1) ? (
                                <>                                 
                                <Box sx={{ padding: '5px'}}>
                                    {/* <Typography component={'h3'} variant="h6" sx={{ fontSize: "20px"}}>
                                        {item?.subtitleText}
                                    </Typography>
                                    <hr />                                     */}

                                    {item?.title1 ?
                                        (
                                            <>
                                                <Typography component={'p'} variant="body2" sx={{ fontSize: "20px", color: "#345C8D", textTransform: "uppercase", marginTop: '5px'}} >
                                                    {item?.title1}
                                                </Typography>
                                                <hr />
                                                <Typography component={'p'} variant="body2" sx={{ fontSize: "15px"}} dangerouslySetInnerHTML={{ __html: item?.paragraph1 }} />
                                            </>
                                        ) : null
                                    }

                                    {item?.title2 ?
                                        (
                                            <>
                                                <Typography component={'p'} variant="body2" sx={{ fontSize: "20px", color: "#345C8D", textTransform: "uppercase", marginTop: 5}} >
                                                    {item?.title2}
                                                </Typography>
                                                <hr />
                                                <Typography component={'p'} variant="body2" sx={{ fontSize: "15px"}} dangerouslySetInnerHTML={{ __html: item?.paragraph2 }} />
                                            </>
                                        ) : null
                                    }

                                    {item?.title3 ?
                                        (
                                            <>
                                                <Typography component={'p'} variant="body2" sx={{ fontSize: "20px", color: "#345C8D", textTransform: "uppercase", marginTop: 5}} >
                                                    {item?.title3}
                                                </Typography>
                                                <hr />
                                                <Typography component={'p'} variant="body2" sx={{ fontSize: "15px"}} dangerouslySetInnerHTML={{ __html: item?.paragraph3 }} />
                                            </>
                                        ) : null
                                    }


                                    {item?.title4 ?
                                        (
                                            <>
                                                <Typography component={'p'} variant="body2" sx={{ fontSize: "20px", color: "#345C8D", textTransform: "uppercase", marginTop: 5}} >
                                                    {item?.title4}
                                                </Typography>
                                                <hr />
                                                <Typography component={'p'} variant="body2" sx={{ fontSize: "15px"}} dangerouslySetInnerHTML={{ __html: item?.paragraph4 }} />
                                            </>
                                        ) : null
                                    }


                                    {item?.title5 ?
                                        (
                                            <>
                                            <Typography component={'p'} variant="body2" sx={{ fontSize: "20px", color: "#345C8D", textTransform: "uppercase", marginTop: 5}} >
                                                    {item?.title5}
                                                </Typography>
                                                <hr />
                                                <Typography component={'p'} variant="body2" sx={{ fontSize: "15px"}} dangerouslySetInnerHTML={{ __html: item?.paragraph5 }} />
                                            </>
                                        ) : null
                                    }
                                </Box>
                               
                                </>
                                ) :  null
                            ))}
                        </>
                        }
                        </Box>                     
                    </Box>

                    {
                        hasTable === true && 
                        <Box className="product-image" display={'block'} paddingLeft={5} paddingTop={0}>
                            <Box display={''} alignItems={"center"}>                           
                                <Typography
                                className="subtitle"
                                component={'h2'}
                                variant="h2"
                                align="center"
                                sx={{
                                    textTransform: "uppercase" ,
                                    color: "#345C8D",
                                    fontSize: "25px",
                                    fontWeight: 400,
                                    paddingTop: "14px"
                                }}
                                >
                                    {t("technicalInformation", { ns: NameSpace.NAVIGATION})}
                                </Typography>
                                <hr style={{ margin: '10px 0 10px 0' }}/>
                                <Box
                                sx={{ width: '100%', marginTop: '15px !important', justifyContent: 'center', textAlign: 'center', display: 'flex', mb: 1}}
                                >
                                    <div
                                        className={`${style.content_table} ${style.backgroundImageColor} table_container`}
                                    >
                                        <div id="tableId"
                                            className={`${style.table} ${style.backgroundImageColor}`}
                                        >
                                        </div>
                                    </div>
                                </Box>                              
                            </Box>
                          
                        </Box>
                    } 
                    
            </Box>           
         </Container>
         <ContactSpecificForm
              // dataSheetProduct={dataSheet}
              product={subfamilyDataSheet}
              className="mt-10"
              ref={contactFormRef}
          /> 
            <MediaViewer
            visible={visible}
            onClose={closeImageViewer}            
            activeIndex={activeImageIndex}
            images={listImages}
            />        
        </>

    )
}

export default SubfamilyDesktop;



const buildingTable = (data: DataSheetFeatureWithTranslation[], languages: string) => {

    const table = document.createElement('table');
    table.classList.add(style.responsiveTable);


    // if(data && data.length === 0){
    //     const existingElement = document.getElementById('tableId');
    //     if(existingElement) existingElement.remove();
    // }
          
     const uniqueModels = [...new Set(data.map(item => item.feature.model))];
     uniqueModels.sort((a, b) => a - b);         
    
     const headerRow = document.createElement('tr');
 
     
     const languageToKeyword = {
         "PT": "MODELO",
         "EN": "MODEL",
         "FR": "MODÈLE",
         "ES": "MODELO",
     };
 
     
     const languageResult = languageToKeyword[languages] || "Model"; 
      
     const emptyHeaderCell = document.createElement('th');
     emptyHeaderCell.textContent = languageResult;
     emptyHeaderCell.classList.add(style.greentext);
     emptyHeaderCell.style.textAlign = 'center';
     emptyHeaderCell.style.fontSize = '11px';
     headerRow.appendChild(emptyHeaderCell);
 
     uniqueModels.forEach(model => {
         const headerCell = document.createElement('th');
         headerCell.textContent = model;
         headerCell.style.textAlign = 'center';
         headerCell.style.fontSize = '11px';
         
         headerCell.classList.add(style.tableHeader);
         headerRow.appendChild(headerCell);
     });
      
     table.appendChild(headerRow);
  
     const addedTranslations = new Set();
  
     data.forEach(item => {
         const translation = item.translations[0]?.name; 
          
         if (!addedTranslations.has(translation)) {
             const translationRow = document.createElement('tr');
 
             const translationCell = document.createElement('td');
             translationCell.style.fontSize = '11px';
             translationCell.textContent = translation;
             translationRow.appendChild(translationCell);
 
             uniqueModels.forEach(model => {
                 const matchingItem = data.find(d => d.translations[0]?.name === translation && d.feature.model === model);
                 let value = matchingItem ? matchingItem.feature.value || '-' : '-';
 
                 const valueCell = document.createElement('td');
                 valueCell.style.textAlign = 'center';
                 valueCell.style.fontSize = '11px';
                  
                 if (matchingItem && (matchingItem.feature.id.includes("Mec-06") || matchingItem.feature.id.includes("Mir-06") || matchingItem.feature.id.includes("06"))) {
                     const imageElement = document.createElement('img');
                     imageElement.src = `https://mm.mafirol.info/Categories/Subfamilies/EnergyEfficiency/${value}`;
                     imageElement.width = 60;
                     valueCell.appendChild(imageElement);
                 } else {
                     valueCell.textContent = value;
                 }
                 translationRow.appendChild(valueCell);
             }); 
             
             table.appendChild(translationRow);
              
             addedTranslations.add(translation);
         }
     });
 
  
     const tableContainer = document.getElementById('tableId');
     if (tableContainer) {
         tableContainer.innerHTML = '';
     }
   
     const tableContainerNew = document.getElementById('tableId');
     if (tableContainerNew) {
        tableContainerNew.appendChild(table);
     }
};