import { useState, useEffect, useCallback, Fragment } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import { MediaModel } from "../../models/MediaModel";

import MediaViewer from "../MediaViewer/MediaViewer";

import "./MediaCollection.css";

interface Props {
  mediaCollection: MediaModel[];
  height?: string;
}

const MediaCollection = (props: Props) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [visible, setVisible] = useState(false);
  const [marginLabel, setMarginLabel] = useState(false);

  const openImageViewer = useCallback((index) => {
    setVisible(true);
    setCurrentImage(index);
  }, []);

  const closeImageViewer = () => {
    setVisible(false);
    setCurrentImage(0);
  };

  var imagesViewer: { src: string }[] = [];

  useEffect(() => {
    for (let i = 0; i < props.mediaCollection.length; i++) {
      if (props.mediaCollection[i].caption) {
        setMarginLabel(true);
        break;
      }
    }
  }, [props.mediaCollection]);

  return (
    <>
      <Container className="media-collection">
        <Row>
          <Col>
            <ul className="media-collection-list">
              {props.mediaCollection.map((item, index) => {
                imagesViewer.push({ src: item.path });
                return (
                  <Fragment key={index}>
                    <li
                      className={`media-collection-list-item ${
                        marginLabel
                          ? "media-collection-list-item-label-margin"
                          : "media-collection-list-item-no-label-margin"
                      }`}
                      style={{
                        height: `${props.height ? props.height : "19vh"}`,
                      }}
                      key={index}
                    >
                      <Image
                        src={item.path}
                        className="media-collection-list-item-media"
                        onClick={() => {
                          openImageViewer(index);
                        }}
                        fluid
                      />
                      {item.caption ? (
                        <div className="media-collection-list-item-label">
                          {item.caption}
                        </div>
                      ) : null}
                    </li>
                  </Fragment>
                );
              })}
              <li className="media-collection-list-item"></li>
            </ul>
          </Col>
        </Row>
      </Container>
      <MediaViewer
        visible={visible}
        onClose={closeImageViewer}
        activeIndex={currentImage}
        images={imagesViewer}
      />
    </>
  );
};

export default MediaCollection;
