import { Container, Row, Col } from "react-bootstrap";

import { FamilyModel } from "../../models/FamilyModel";
import { TypeModel } from "../../models/TypeModel";

import MainTitle from "../../components/MainTitle/MainTitle";
import FamilyDescription from "../../components/FamilyDescription/FamilyDescription";
import BreadcrumbPath from "../../components/BreadcrumbPath/BreadcrumbPath";
import CategoryItem from "../../components/CategoryItem/CategoryItem";

import "./Family.css";
import { useCallback, useEffect, useState } from "react";
import MediaViewer from "../MediaViewer/MediaViewer";
import DataSheetService from "../../services/api/DataSheetService";
import DataSheetEntitie from "../../models/DataSheetEntitie";
import Util from "../../utils/Util";
import i18n from "../../i18n";

interface Props {
  family?: FamilyModel;
  downloadDocument: (item: TypeModel) => void;
  askInformation: () => void;
  className?: string;
}



const Family = (props: Props) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [visible, setVisible] = useState(false);
  const language = Util.splitLanguage(i18n.language, 0);
  // const[dataSheets, setDataSheets] = useState<DataSheetEntitie[]>([]);
  const [familyId, setFamilyId] = useState<any>()

  const openImageViewer = useCallback((index) => {
    setVisible(true);
    setCurrentImage(index);
  }, []);
  
  const closeImageViewer = () => {
    setVisible(false);
    setCurrentImage(0);
  };

  var images: { src: string }[] = [];

    useEffect(() => {

      setFamilyId(props.family?.id)
    },)

    // useEffect(() =>{   
    //   if(props.family?.id) {
    //     DataSheetService.getDataSheetsByFamily(familyId, language.toUpperCase()).then((res) =>{
    //       setDataSheets(res.data);
    //     })  
    //   }           
    //   },[familyId])

  return (
    <Container fluid className={`family ${props.className}`}>
      <Row>
        <Col md={8} className="pl-0">
          <Row>
            <Col>
              <MainTitle media={props.family?.media} height="58vh" />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="family-breadcrumb">
              <BreadcrumbPath
                ancestors={props.family?.ancestors}
                current={props.family?.name}
              />
            </Col>
          </Row>
          <article>
            <Row className="family-subfamilies ml-auto">
              {props.family?.subfamilies.map((item, index) => {
                images.push({ src: item.primaryMedia.path });
                return (
                  <Col
                    md={4}
                    className="family-subfamilies-col p-0"
                    key={index}
                  >
                    <CategoryItem
                      primaryMedia={item.primaryMedia}
                      secondaryMedia={item.secondaryMedia}
                      name={item.name}
                      energyEfficiencyMedia={item.energyEfficiencyMedia}
                      style={{
                        nameClassName: "text-center text-uppercase",
                        scaleImage: true,
                        background: true,
                      }}
                      key={index}
                      path={`${props.family?.path}/${item.id}`}
                      // imageViewer={() => openImageViewer(index)}
                    />
                  </Col>
                );
              })}
            </Row>
          </article>
        </Col>
       
        <Col md={3} className="position-relative ml-5">
          <FamilyDescription
            articleClassName="mt-5 pt-5"
            family={props.family}
            askInformationColSpec={{ lg: 12, xl: 6 }}
            documentsColSpec={{ lg: 12, xl: 6 }}
            downloadDocument={props.downloadDocument}
            askInformation={props.askInformation}
          
            // dataSheets={dataSheets}
          />
        </Col>

      </Row>
      <MediaViewer
        visible={visible}
        onClose={closeImageViewer}
        activeIndex={currentImage}
        images={images}
      />
    </Container>
  );
};

export default Family;
