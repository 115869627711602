import { match } from "react-router";
import { useState, useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { NameSpace } from "../../data/AppLanguage";

import Util from "../../utils/Util";

import EventService from "../../services/api/Event";

import MainTitle from "../../components/MainTitle/MainTitle";
import BreadcrumbPath from "../../components/BreadcrumbPath/BreadcrumbPath";

import "./Events.css";
import { EventModel } from "../../models/EventModel";
import MediaCover from '../../components/MediaCover/MediaCover'; // Importe o componente MediaCover
import React from 'react';
import '../../../node_modules/video-react/dist/video-react.css';
import { Player } from  'video-react';
import { MediaType } from "../../enums/MediaType";


interface Path { 
  path: string;
}

interface Props {
  match: match<Path>;
}

interface HtmlTagsRemoverProps {
  text: string;
}

const Events = (props: Props) => {
  const [event, setEvent] = useState<EventModel>();

  const params = props.match.params;

  const { t } = useTranslation([
    NameSpace.COMMON,
    NameSpace.NAVIGATION,
    NameSpace.PAGES,
    NameSpace.ROUTES,
  ]);
  const eventsPath = t("newsEvents", { ns: NameSpace.ROUTES });
  const eventsTitle = t("events", { ns: NameSpace.PAGES });

  function removeHtmlTags({ text }: HtmlTagsRemoverProps) {
    let temp = document.createElement("div");
    temp.innerHTML = text;
    return temp.textContent || temp.innerText || "";
  }

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      EventService.getEvent(params.path).then((res) => {
        document.title = Util.setDocumentTitle(
          `${eventsTitle} | ${res.data.subtitle}`
        );
        setEvent(res.data);
      }).catch((error) => {
        console.log(error);
      });
    }

    return () => {
      mounted = false;
    };
  }, [params.path, eventsTitle]);
  

  return (
    <>
      <MainTitle
        media={event?.mediaMain}
        height="58vh"
        articleClassName="event-maintitle"
      />
      <BreadcrumbPath
        ancestors={[        
          {
            name: eventsTitle,
            path: eventsPath,
          },
        ]}
        current={event?.subtitle}
        className="pt-8"
      />
      <Container className="justify-content-center text-align-center">
        <article>
          <Container className="event justify-content-center text-align-center">
            <Row>
              <Col className="event-title">{event?.subtitle}</Col>
            </Row>
            <hr />
            <Col className="peventer02 text-align-center">  
            {
             event?.mediaMain.path && event?.mediaMain.type === MediaType.VIDEO && (
              <Player
              playsInline
              poster="/assets/poster.png"
              src={event?.mediaMain.path}
              autoPlay
              
            />
             )
             
            }
             {
             event?.mediaMain.path && event?.mediaMain.type === MediaType.IMAGE && (
              <img src={event.mediaMain.path} alt="harry potter" style={{ width: '50vw', }}/>
             )
             
            }          
            </Col> 
            <Row>
              <Col className="mt-4">
                <hr />
              </Col>
            </Row>
            <Row>
              <Col>
                <div>{event?.section1 && removeHtmlTags({ text: event?.section1 })}</div>
              </Col>
            </Row>
            <Row className="event-disclaimer"></Row>
          </Container>
        </article>
      </Container>
    </>
  );
};

export default Events;
              