import { match } from "react-router";
import { useState, useEffect } from "react";
import { Container, Row, Col, Image, Carousel, Pagination } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NameSpace } from "../../data/AppLanguage";
import Util from "../../utils/Util";
import SolutionsService from "../../services/api/Solutions";
import BreadcrumbPath from "../../components/BreadcrumbPath/BreadcrumbPath";
import "./Solutions.css";
import { SolutionModel } from "../../models/SolutionModel";
import i18n from "../../i18n";

interface Path { 
  path: string;
}

interface Props {
  match: match<Path>;
}

interface HtmlTagsRemoverProps {
  text: string;
}

const Solutions = (props: Props) => {
  // console.log('Chegou aqui!')
  const [solution, setSolution] = useState<SolutionModel>();
  const [solutions, setSolutions] = useState<SolutionModel[]>([]);
  // const [activeIndex, setActiveIndex] = useState(0);
  const [activeIndexes, setActiveIndexes] = useState<number[]>([]);

  // const handleSelect = (selectedIndex: number) => {
  //   setActiveIndex(selectedIndex);
  // };

  const handleSelect = (index: number, selectedIndex: number) => {
    const newActiveIndexes = [...activeIndexes];
    newActiveIndexes[index] = selectedIndex;
    setActiveIndexes(newActiveIndexes);
  };
  

  const params = props.match.params;

  const { t } = useTranslation([
    NameSpace.COMMON,
    NameSpace.NAVIGATION,
    NameSpace.PAGES,
    NameSpace.ROUTES,
  ]);

  const language = Util.splitLanguage(i18n.language, 0);

  const solutionsPath = t("solutions", { ns: NameSpace.ROUTES });
  const solutionsTitle = t("solutions", { ns: NameSpace.PAGES });

  const galleryTitle = t("imageGallery", { ns: NameSpace.ROUTES });
  const galleryPath = t("imageGallery", { ns: NameSpace.PAGES });

  function removeHtmlTags({ text }: HtmlTagsRemoverProps) {
    let temp = document.createElement("div");
    temp.innerHTML = text;
    return temp.textContent || temp.innerText || "";
  }

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      const gammaPath = localStorage.getItem('gammaPath')
      // console.log('o gammaPath no componente final é: ', gammaPath)

      if(gammaPath)
      {
        SolutionsService.getGammaSolutions(gammaPath, language).then((res) => {
            setSolutions(res.data);
          // console.log('São os carrosséis que vieram do back: ', res.data)

          // Inicialize o estado activeIndexes com um valor padrão para cada carrossel
          setActiveIndexes(new Array(res.data.length).fill(0));
        }).catch((error) => {
          console.log(error);
        });
      }
    }

    return () => {
      mounted = false;
    };
  }, [params.path, solutionsTitle]);
  

  return (
    <>
      <BreadcrumbPath
        ancestors={[
          {
            name: solutionsTitle,
            path: solutionsPath,
          },
          {
            name: galleryTitle,
            path: galleryPath,
          },
        ]}
        current={solution?.subtitle}
        className="pt-8"
      />
  
      {/* É um foreach de cada carrossel da página */}
      <article className="artigo">
        <div className="container">
        {solutions.length > 0 ? 
            solutions?.map((item, index) => (
              <div key={index} className="event">
                <div className="pb-4">
                  <Col className="event-title titulo">{item?.subtitle}</Col>
                </div>
                <div className="pb-5 titulo titulo01">
                  <Col>
                    <div>{item?.section1 && removeHtmlTags({ text: item?.section1 })}</div>
                  </Col>
                </div>

                {/* Abaixo, tem-se o carrossel e seu thumbnail abaixo dele */}
                <Carousel activeIndex={activeIndexes[index]} onSelect={(selectedIndex) => handleSelect(index, selectedIndex)}>
                  {item?.solutionsBackMediaCollection?.map((media, mediaIndex) => (
                    <Carousel.Item key={mediaIndex} className="image-carousel">
                      <Container className="container-media">
                        <Image
                          className="image-media"
                          src={media.path}
                          alt={item?.subtitle}
                        />
                      </Container>
                    </Carousel.Item>
                  ))}
                </Carousel>
                <Pagination className="mt-4 pb-3 pagination">
                  {item?.solutionsBackMediaCollection?.map((_, mediaIndex) => (
                    <Pagination.Item
                      key={mediaIndex}
                      active={mediaIndex === activeIndexes[index]}
                      onClick={() => handleSelect(index, mediaIndex)}
                    >
                      <Image
                        src={_.path}
                        alt={item?.subtitle}
                        style={{ width: "6.25rem", height: "6.25rem" }}
                      />
                    </Pagination.Item>
                  ))}
                </Pagination>
                {/* acima, tem-se o carrossel e seu thumbnail abaixo dele */}
                <Row className="event-disclaimer"></Row>
              </div>

            )
                           
            ) : (
                  <div className="alert alert-warning" role="alert" style={{ width: "70rem", height: "30rem" }}>
                    <h5 className="text-align-center dados p-0 m-0">
                      {t("data", {ns: NameSpace.PAGES})}
                    </h5>
                  </div>
                )}                                            
          
        </div>
      </article>
    </>
  );
};

export default Solutions;
