import { Container, Row, Col, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { CategoryModel } from "../../models/CategoryModel";

import { NameSpace } from "../../data/AppLanguage";

import CategoryItem from "../CategoryItem/CategoryItem";

import "./Category.css";




interface Props {
  category?: CategoryModel;
  nameClassName?: string;
  borderBottom?: boolean;
  overlay?: boolean;
  scaleImage?: boolean;
  background?: boolean;
}

const Category = (props: Props) => {
  const { t } = useTranslation([NameSpace.PAGES]);

  return (
    <article className="pb-5 mb-8">
      <Container className="category">
        <Row className="category-wrapper">
          {props.category?.successors.length ? (
            props.category?.successors.map((item, index) => {
              return (
                <Col
                  xs={12}
                  md={6}
                  xl={4}
                  key={index}
                  className="category-wrapper-col"
                >
                  <CategoryItem
                    category={props.category}
                    primaryMedia={item.media}
                    name={item.name}
                    review={{ text: item.review, textField: item.reviewField }}
                    path={item.path}
                    style={{
                      cardClassName: "mx-auto",
                      nameClassName: props.nameClassName,
                      borderBottom: props.borderBottom,
                      overlay: props.overlay,
                      scaleImage: props.scaleImage,
                      background: props.background,
                    }}
                  />
                </Col>
              );
            })
          ) : (
            <Col>
              <Alert variant="warning" className="category-wrapper-no-content">
                {t("noContent")}
              </Alert>
            </Col>
          )}
        </Row>
      </Container>
    </article>
  );
};

export default Category;
