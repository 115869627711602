
import { MediaType } from "../../enums/MediaType";
import { useContext, useEffect, useState } from "react";
import { OpportunityContext } from "../../context/ThemeContext";
import Typography from '@mui/joy/Typography';
import * as Typogr from "@mui/material";
import { NameSpace } from "../../data/AppLanguage";
import { useTranslation } from "react-i18next";
import OpportunityGammaModel from "../../models/OpportunityGammaModel";


import Util from "../../utils/Util";
import { Alert } from "react-bootstrap";
import { Box, Container, Grid,  } from "@mui/material";

import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import IconButton from '@mui/joy/IconButton';
import CardActions from '@mui/material/CardActions';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { Link, useHistory, useParams } from "react-router-dom";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import OpportunityService from "../../services/Opportunity";
import { MdArrowBack } from "react-icons/md";


const OpportunitiesByGamma = () => {      
   const [opportunitty, setOpportunitty ] = useState<OpportunityGammaModel>();
   const [mounted, setMounted] = useState<boolean>(true);
   const history = useHistory();

   const { path } = useParams<{ path: string | undefined }>();
   const params1 = useParams();


   const { t, i18n } = useTranslation([
    NameSpace.COMMON,
    NameSpace.GLOSSARY,
    NameSpace.NAVIGATION,
    NameSpace.ROUTES,
    NameSpace.PAGES,
    ]);

    const noContent = t("noContent", { ns: NameSpace.PAGES });

    const language = Util.splitLanguage(i18n.language, 0);    

   useEffect(() => {
//     console.log("o id de gamma ´r: " , path)
// console.log('params:', params1);

    if(language && path){
       OpportunityService.getGroupedOpportunityByGamma(language, path).then((res) =>{
        setOpportunitty(res.data);
         console.log("lista agrupada: ", res.data)
       })
    }      
},[language, path]);

   

    return (
      <>
      <div>

          {opportunitty !== null ? (
            <Container maxWidth="xl" className="" sx={{ my: 20}}>
                  <Typogr.Typography component="h1" variant="h3" align="left">
                      {t("opportunities", { ns: NameSpace.NAVIGATION })} - {opportunitty?.title}
                  </Typogr.Typography>     

                  <hr />
                  <button button className="btn btn-info mb-3" onClick={() => history.goBack()}><MdArrowBack className="variant-wrapper-tabs-button-prev-icon" /></button>
                    
                  <Box sx={{ mt: 10 }}>
                    <Grid container      
                      sx={{ pt: 4, pb: 10}}                                                                                
                    >             
                      {
                        opportunitty?.opportunitiesCollection.map((opportunity, index) => (
                          <Grid item
                          xl={3}
                          lg={4}
                          md={4}
                          sm={6}
                          xs={12}
                          key={index}
                          >                                  
                            {/* <Link to={`/${language}/${t("opportunity", {ns: NameSpace.ROUTES,})}/opportunityitem/${opportunity.id}`} onClick={() => handleContext(opportunity.id)}>            */}
                            <Link to={`/${language}/${t("opportunity", {ns: NameSpace.ROUTES,})}/opportunityitem/${opportunity.id}`}>           

                              <Card sx={{ width: 345, backgroundColor: '#ffffff', border: 'none' , marginBottom: 10}}>
                                <div>
                                  <Typography level="title-lg">{opportunity.opportunittyTranslations[0].title}</Typography>
                                  <Typography level="body-sm">{opportunity.reference ? `Ref: ${opportunity.reference}` : `Ref: -`}</Typography>                                         
                                </div>

                                <AspectRatio minHeight="200px" maxHeight="200px">
                                  <img
                                    src={opportunity.opportunityMediaCollection[0]?.name ?? ""}
                                    srcSet={opportunity?.opportunityMediaCollection[0]?.name ?? ""}
                                    loading="lazy"
                                    alt="image"
                                  />
                                  {/* <img
                                    src={"https://source.unsplash.com/random?=3"}
                                    srcSet={"https://source.unsplash.com/random?=3"}
                                    loading="lazy"
                                    alt="image"
                                  /> */}
                                </AspectRatio>

                                <CardContent orientation="horizontal">
                                  <Box>
                                    <Typography level="body-sm">
                                      {t("totalPrice", { ns: NameSpace.NAVIGATION })}
                                    </Typography>                    

                                    <Box display={'flex'}>
                                      <Typogr.Typography variant="h6" color="text.secondary" sx={{ textDecoration: "line-through"}}>
                                      {opportunity.previousPrice ? `€ ${opportunity.previousPrice}` : ""} 
                                      </Typogr.Typography>
                                      
                                      <Typogr.Typography variant="h6" color="text.secondary" sx={{ marginLeft: opportunity.previousPrice !== ""  ? 2 : 0}}>
                                      € {opportunity.currentPrice}
                                      </Typogr.Typography>
                                    </Box>       
                                      
                                  </Box>
                                </CardContent>
                                
                                <CardActions disableSpacing>
                                  <Typogr.Typography component={'h6'} variant="h6" aria-label="add to favorites" color="text.secondary">
                                      {t("knowMore", { ns: NameSpace.NAVIGATION})}
                                  </Typogr.Typography>

                                  <IconButton aria-label="know-more">                                        
                                    <ControlPointIcon />
                                  </IconButton>                                      
                                </CardActions>
                              </Card>
                                  
                            </Link>  
                          </Grid>
                        ))
                      }
                      
                    </Grid>
                  </Box>   
              </Container>
          ) : (
              <Container maxWidth="xl" className="mt-10" style={{height: '100vh'}}>
                
                  <Typogr.Typography component="h1" variant="h3" align="left">
                      {t("opportunities", { ns: NameSpace.NAVIGATION })}
                  </Typogr.Typography>     

                  <hr  />
                  
                  <Alert variant="warning" className="category-wrapper-no-content text-center mt-5">
                      <Typogr.Typography component="h6" variant="h6" align="center">
                          {noContent}
                      </Typogr.Typography>                          
                  </Alert>
                  
              </Container>                
          )}
           
      </div>
  </>
    );
  };
  
  export default OpportunitiesByGamma;
                
