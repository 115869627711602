import { useTranslation } from "react-i18next";
import { NameSpace, buttons } from "../../data/AppLanguage";
import { useContext, useEffect, useRef, useState } from "react";
import { OpportunityContext } from "../../context/ThemeContext";

import Util from "../../utils/Util";
import { Alert, Button } from "react-bootstrap";

import OpportunityModel from "../../models/OpportunityModel";

import {  
    Container,
    Grid,
    Box,
    Typography,
    Chip,
    Card,
    CardMedia,
 } from '@mui/material';
 import Carousel from 'react-material-ui-carousel';

import styles from './Opportunity.module.css';
import ContactSpecificForm from "../../components/ContactSpecificForm/ContactSpecificForm";
import { useHistory, useParams } from "react-router-dom";
import OpportunityService from "../../services/Opportunity";
import { MdArrowBack } from "react-icons/md";

const Opportunity = () => {
    const [opportunity, setOpportunitty] = useState<OpportunityModel>();
    const { opportunittyByGamma } = useContext(OpportunityContext);
    const contactFormRef = useRef<HTMLElement>(null);
    const history = useHistory();

    const { t, i18n } = useTranslation([
        NameSpace.COMMON,
        NameSpace.GLOSSARY,
        NameSpace.NAVIGATION,
        NameSpace.ROUTES,
        NameSpace.PAGES,
    ]);
    
    const noContent = t("noContent", { ns: NameSpace.PAGES });

    const language = Util.splitLanguage(i18n.language, 0);

    const { path } = useParams<{ path: string | undefined }>();

    useEffect(() => {
            
            if(language && path){
               OpportunityService.getOpportunityById(language, path).then((res) =>{
                setOpportunitty(res.data);
                 console.log("resultado do model: ", res.data)
               })
            }      
        },[language, path]);

 
    const askInformation = () => {
        if (contactFormRef.current) {
          contactFormRef.current.scrollIntoView({
            block: "start",
            behavior: "smooth",
          });
        }
      };

return (
    <>
        <Box sx={{backgroundColor: "#bdbdbd80"}}>
            {/* <h1>Página do item com carrossel</h1> */}
            {opportunity !== null ? (
                <Container maxWidth="lg" sx={{pt: 15}}>
                         <button className="btn btn-info mb-3" onClick={() => history.goBack()}><MdArrowBack className="variant-wrapper-tabs-button-prev-icon" /></button>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={8} lg={8}>
                            <Box className={styles.box}>
                            <Typography component={'h2'} variant={'h4'} display={'flex'} sx={{ alignItems: 'center'}}>
                                {opportunity?.opportunittyTranslations[0]?.title}
                            </Typography>   
                    <hr/>
                                <Carousel
                                    autoPlay={false}
                                    animation="slide"
                                    navButtonsAlwaysVisible
                                    navButtonsProps={{
                                        style: {
                                            color: 'white'
                                        }
                                    }}                            
                                    >
                                    {
                                     opportunity?.opportunityMediaCollection?.sort((a, b) => Number(a.position) - Number(b.position)).map((media, index) => (
                                        <Card className={styles.card} key={index}>
                                            <CardMedia 
                                                className={styles.cardMedia}
                                                image={media.name ?? ""}
                                                title="Título da imagem"
                                            />
                                        </Card>
                                     ))   
                                    }
                                </Carousel>                           
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Card elevation={0} className={styles.box}>
                                <Typography component="span" variant="caption">{opportunity?.reference ? `Ref: ${opportunity?.reference}` : "Ref: -"}</Typography>
                                <Typography component="h4" variant="h4" className={styles.productName}>{opportunity?.opportunittyTranslations[0]?.title}</Typography>
                                {
                                    opportunity?.previousPrice 
                                    ?
                                    (
                                        <Chip label={opportunity?.previousPrice ? `€${opportunity?.previousPrice}` : ""}  sx={{ textDecoration: "line-through", my:1}}/>
                                    )
                                    : null
                                }                            
                                <Typography component="h4" variant="h4" className={styles.price} sx={{ color: '#00000094'}}>
                                    € {opportunity?.currentPrice}
                                </Typography>
                            </Card>

                            <Box className={styles.box}> 
                                <Typography component="h6" variant="h6"> {t("description", { ns: NameSpace.NAVIGATION })}</Typography>
                                <Typography component="p" variant="body2"
                                    dangerouslySetInnerHTML={{ __html: opportunity?.opportunittyTranslations[0]?.description}} 
                                    sx={{ color: '#00000094'}}
                                >
                                </Typography>
                            </Box>

                            <Box className={styles.box}>                           
                                <Box
                                className="family-description-buttons-info pl-2 pr-2 d-flex justify-content-center"
                                >
                                    <Button variant="primary" onClick={() => askInformation()}>
                                        {t(`${buttons}askInformation`, { ns: NameSpace.COMMON })}
                                    </Button>
                                </Box>                                     
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            ) : (
                <Container maxWidth="xl" className="mt-10" style={{height: '100vh'}}>
                  
                    <Typography component="h1" variant="h3" align="left">
                        {t("opportunities", { ns: NameSpace.NAVIGATION })}
                    </Typography>     

                    <hr  />
                    
                    <Alert variant="warning" className="category-wrapper-no-content text-center mt-5">
                        <Typography component="h6" variant="h6" align="center">
                            {noContent}
                        </Typography>                          
                    </Alert>
                    
                </Container>                
            )}
        <ContactSpecificForm
            // category={ { id: opportunity?.id ?? "" , name: opportunity?.opportunittyTranslations[0].title ?? "", } }
            className="mt-10"
            ref={contactFormRef}
        />          
        </Box>
    </>
);
}

export default Opportunity;